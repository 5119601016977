<template>
  <section class="map">
    <!-- <div class="contact__map"> -->
        <iframe :src="map.map" class="map__map"></iframe>
    <!-- </div> -->
  </section>
</template>

<script>
export default {
  data() {
    return {
      // paragraphs: this.$store.state.header.homeParagraph,
    };
  },
  props: {
    map: {
      type: Object,
      required: true,
    },
  },
  name: "GoogleMap",
};
</script>

<style lang="scss" scoped>
.map {
  margin: 4rem 0;
  text-align: center;

  &__map {
    // width: 1088px;
    height: 286px;
    margin-top: 5rem;
    border: none;
    width: 85vw;
  }
}

</style>


