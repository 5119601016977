<template>
<div>
  <Header :headerData="headerData" />
  <main class="main">
    <ImageExplainerText :about="about" />
    <CompanyProfileDownload :companyProfile="companyProfile" :invertColors="invertColors" />
  </main>
  <Footer :footer="footer" />
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
import ImageExplainerText from "@/components/ImageExplainerText.vue";
import CompanyProfileDownload from "@/components/CompanyProfileDownload.vue";
import Footer from "@/components/Footer.vue";

export default {
  data() {
    return {
      headerData: {
        paragraphs: this.$store.state.header.about.text,
        image: this.$store.state.header.home.image,
      },

      about: this.$store.state.about,

      footer: this.$store.state.footer,

      companyProfile: this.$store.state.companyProfile,

      invertColors: true
    };
  },
  name: "About",
  components: {
    Header,
    ImageExplainerText,
    CompanyProfileDownload,
    Footer,
  },
};
</script>


 <style lang="scss" scoped>

// .profileDownload {
//   background-color: #00865d;
//   color: #fff;

//   &__download-button {
//     color: #fff;
//     background-color: #000 !important;
//   }
// }

</style>