<template>
  <section class="products">
    <h2 class="products__heading">{{ products.heading }}</h2>

    <div class="products__container">
      <div
        v-for="product in products.productList"
        :key="product.index"
        class="products__product-card"
      >
        <img
          v-bind:src="require('@/assets/images/' + product.img)"
          alt="test"
          class="products__product-img"
        />
        <p class="products__product-description">
          {{ product.description }}
        </p>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      // paragraphs: this.$store.state.header.homeParagraph,
    };
  },
  props: {
    products: {
      type: Object,
      required: true,
    },
  },
  name: "ProductImageList",
};
</script>

<style lang="scss" scoped>
.products {
  margin: 4rem 15rem;
  text-align: center;

  @media screen and (max-width: 868px) {
      margin: 4rem 5rem;
    }

  &__heading {
    margin-bottom: 5rem;

    margin-bottom: 5rem;
    @media screen and (max-width: 1041px) {
      font-size: 3.8rem;
    }
    @media screen and (max-width: 650px) {
      font-size: calc(2.6rem + 1vw)
    }
  
  }

  &__container {
    display: flex;
    // gap: 3rem rem;
    align-items: flex-start;
    justify-content: center;
    flex-wrap: wrap;
  }

  &__product-card {
    flex: 0 0 20%;
    margin: 0 1rem;
  }

  &__product-description {
    font-size: 1.9rem;
    font-weight: 800;
    background-color: #F8F8F8;
    padding: 2rem
  }
}

// &__card {
//   width: 30%;
// }

// &__card-text {
//   padding: 2rem;
//   background-color: #f8f8f8;
// }

// &__card-title {
//   font-size: 2rem;
//   font-weight: 800;
//   color: #00865d;
// }

// &__card-description {
//   font-size: 2.5rem;
//   font-weight: 600;
// }

// &__card-body-text {
//   font-size: 1.8rem;
//   width: 73.86%;
//   margin: auto;
</style>


