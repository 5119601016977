<template>
<div>
  <Header :headerData="headerData"/>
  
  <main>
    <GoogleMap :map="map" />
    <ContactForm :contact="contact"/>
    
  </main>
  <Footer :footer="footer"/>
  </div>
</template>

<script>

import Header from "@/components/Header.vue";
import GoogleMap from "@/components/GoogleMap.vue";
import ContactForm from "@/components/ContactForm.vue";
import Footer from "@/components/Footer.vue";

export default {
  data() {
    return {
        headerData: {
          paragraphs: this.$store.state.header.contact.text,
          image: this.$store.state.header.contact.image,
          id: this.$store.state.header.contact.id
        },

        map: this.$store.state.map,

        footer: this.$store.state.footer
        
    }
  },
  name: "Contact",
  components: {
    Header,
    GoogleMap,
    ContactForm,
    Footer
  },

};
</script>


<style lang="scss" >
.header__image {
        transform: rotate(1deg) !important;
}
.header {
  @media screen and (max-width: 868px) {
      text-align: center;
      padding-bottom: 2rem;
    }
}


</style>