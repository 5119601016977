<template>
<div>
  <Header :headerData="headerData" />
  <main>
    <ProductImageList :products="products" />
    <Subscribe />
    <ClientSlider :clients="clients" :invertColors="invertColors" />
  </main>
  <Footer :footer="footer" />
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
import ProductImageList from "@/components/ProductImageList.vue";
import Subscribe from "@/components/Subscribe.vue";
import ClientSlider from "@/components/ClientSlider.vue";
import Footer from "@/components/Footer.vue";

export default {
  data() {
    return {
      headerData: {
        paragraphs: this.$store.state.header.parts.text,
        image: this.$store.state.header.home.image,
      },

      products: this.$store.state.products,

      clients: this.$store.state.clients,

      footer: this.$store.state.footer,

      invertColors: false
    };
  },

  name: "Parts",
  components: {
    Header,
    ProductImageList,
    Subscribe,
    ClientSlider,
    Footer,
  },

  // mounted() {
  //   const sliderArrows = document.querySelector(".v-hl-svg");
  //   sliderArrows.style.width = "30px";
  //   // sliderArrows.style.border = "2px solid green";
  // },
};
</script>


<style lang="scss" scoped>

// //**********************************************************************************
//***************** ClientSlider ***********************************************
// *********************************************************************************/
.v-hl-container {
  display: flex;
  justify-content: center !important;


  @media screen and (max-width: 1326px) {
      justify-content: flex-start !important;
    }
}

.v-hl-svg {
  border: 2px solid green !important;
}
</style>