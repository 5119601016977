<template>
  <section class="contact">
    
    
      <div id="mauticform_wrapper_stdcontactform" class="mauticform_wrapper">
        <form autocomplete="false" role="form" method="post" action="https://bennbliss.co.za/mautic/form/submit?formId=1" id="mauticform_stdcontactform" data-mautic-form="stdcontactform" enctype="multipart/form-data">
            <div class="mauticform-error" id="mauticform_stdcontactform_error"></div>
            <div class="mauticform-message" id="mauticform_stdcontactform_message">
            </div>
            <div class="mauticform-innerform">


                <div class="mauticform-page-wrapper mauticform-page-1" data-mautic-form-page="1">
                  <div class="contact__component-container">

                    <div class="contact__text">
                      <h2 class="contact__title">CONTACT</h2>
                    <p class="contact__heading">Contact us.<br/>We generally respond within 24hours</p>
                    </div>

                    <div class="contact__inputs-container">
                    
                    <div class="contact__inputs">
                     
                            <input id="mauticform_input_stdcontactform_subject" name="mauticform[subject]" value="" placeholder="Subject" class="mauticform-input contact__input" type="text">

                            <input id="mauticform_input_stdcontactform_f_name" name="mauticform[f_name]" value="" class="mauticform-input contact__input" type="text" placeholder="name">

                            <input id="mauticform_input_stdcontactform_email" name="mauticform[email]" value="" class="mauticform-input contact__input" type="email" placeholder="email">

                            <!-- <div id="mauticform_stdcontactform_submit" class="mauticform-row mauticform-button-wrapper mauticform-field-5"> -->
                              
                                <button type="submit" name="mauticform[submit]" id="mauticform_input_stdcontactform_submit" value="" class="btn btn-primary contact__input contact__btn">Submit</button>
                            <!-- </div> -->

                    </div>
                    
                    <div class="contact__textarea-container">
<!--
                        <textarea id="mauticform_input_stdcontactform_f_message" name="mauticform[f_message]" cols="30" rows="10" class="mauticform-textarea " placeholder="How can we help? Please type your message here."></textarea> -->
                        <textarea id="mauticform_input_stdcontactform_textarea" name="mauticform[textarea]" class="mauticform-textarea contact__textarea" 
                        placeholder="How can we help? Please type your message here."
                        cols="30" rows="10" ></textarea>


                    </div>
                  </div>
                  </div>
                  

                </div>
            </div>

            <input type="hidden" name="mauticform[formId]" id="mauticform_stdcontactform_id" value="1">
            <input type="hidden" name="mauticform[return]" id="mauticform_stdcontactform_return" value="">
            <input type="hidden" name="mauticform[formName]" id="mauticform_stdcontactform_name" value="stdcontactform">

        </form>
                                </div>

  </section>
</template>

<script>
/*WE WILL ADD ALL THE DATA TO THE STORE FILE
THEN WE WILL CREATE A PROP TO PASS THE DATA THROUGH. (BUT WHY DO WE NEED TO PASS THE DATA THROUGH A PROP WHEN THE DATA IS GOING TO BE STORED IN THE VUEX STORE?) */
export default {
  data() {
    return {
      // paragraphs: this.$store.state.header.homeParagraph,
    };
  },
  props: {
    contact: {
      type: Object,
      required: true,
    },
  },
  name: "ContactForm",
};
</script>

<style lang="scss" scoped>
.contact {
  margin: 4rem 15rem;
  text-align: left;
  margin-left: 21rem;

  @media screen and (max-width: 1200px) {
    margin: 4rem 5rem;
    margin-left: 6rem
  }

  @media screen and (max-width: 750px) {
    margin: 4rem 1.5rem;
    margin-left: 3.5rem
  }

  @media screen and (max-width: 507px) {
    margin: 4rem 1rem;
  }

  &__component-container {
    display: flex;
    flex-direction: column;
    
    
  }

  &__title {
    color: #00865d;
    font-size: 1.75rem;
    font-weight: 600;
    text-transform: uppercase;

    @media screen and (max-width: 550px) {
      visibility: hidden;
      // text-align: center;
  }
  }

  &__heading {
    margin-top: 1rem;
    font-size: 4.2rem;
    color: #000;
    line-height: 1.1;

    @media screen and (max-width: 1140px) {
    font-size: calc(2.5rem + 1vw);
    margin-right: 2rem;
  }
    @media screen and (max-width: 550px) {
      font-size: calc(2.2rem + 1vw);
    text-align: center;
  
    margin-right: 0;
  }
  }

  &__inputs-container {
    margin-top: 6rem;
    display: flex;
    
    @media screen and (max-width: 507px) {
    flex-direction: column;
  }
  }

  &__textarea, &__input {
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    line-height: 1.4;
    color: #2c3e50;
  } 

  &__textarea:focus, &__input:focus {
    outline: none !important;
    border: 1px solid #61e0b9;;
  }

  &__input {
    padding: 1.24rem 1rem;
    font-size: 1.7rem;
    width: 20vw;

    @media screen and (max-width: 1200px) {
    width: 35vw;
  }
    @media screen and (max-width: 507px) {
    width: 90vw;
  }
  

    &:not(:last-child) {
      margin-bottom: 1.5rem;
    }
  }

  &__textarea-container {
    order: -1;
    @media screen and (max-width: 507px) {
    text-align: center;
    margin-bottom: 1.5rem;
  }
    
  }

  &__textarea {
    width: 50vw;
    padding: 1rem;
    font-size: 1.7rem;

    @media screen and (max-width: 507px) {
    width: 90vw;
  }

  }

  &__inputs {
    display: flex;
    flex-direction: column;
    // width: 20vw;
    margin-left: 2rem;

    @media screen and (max-width: 507px) {
    margin-left: 0;
    width: 90vw;
    margin: auto;
  }
  }

  &__btn {
    background-color: #00865d;
    // border-color: #00865d;
    border: none;
    color: #fff;
    border-radius: .2rem;
    
    &:hover {
      cursor: pointer;
    }

    &::after {
        content: url('../assets/images/send.svg');
    }
     
  }
}

</style>


