<template>
<div>
  <Header :headerData="headerData" />
  <main>
    <ImageExplainerText :about="about" />
  </main>
  <Footer :footer="footer" />
  </div>
</template>

<script>

import Header from "@/components/Header.vue";
import ImageExplainerText from "@/components/ImageExplainerText.vue";
import Footer from "@/components/Footer.vue";

export default {
  data() {
    return {
        headerData: {
          paragraphs: this.$store.state.header.repairs.text,
          image: this.$store.state.header.repairs.image,
          id: this.$store.state.header.repairs.id
        },

        about: this.$store.state.about,

        footer: this.$store.state.footer,
        
    }
  },
  name: "Repairs",
  components: {
    Header,
    ImageExplainerText,
    Footer,
  },
};
</script>


<style lang="scss" scoped>
.header {
  padding-top: 5rem;
  padding-bottom: 0;


  &__text {
    @media screen and (max-width: 868px) {
      padding-bottom: 2rem !important;
   
    }
  }
}

.header__text {
  text-align: center !important;
      margin-bottom: 2rem !important;
      background-color: red;
}

#repairs  {
    @media screen and (max-width: 868px) {
      text-align: center !important;
      padding-bottom: 2rem;
    }
    @media screen and (max-width: 560px) {
      padding-top: 10rem;
    }
}

</style>