<template>
  <section class="about">
    <div class="about__container">
        <!-- <div class="about__img-container"> -->
            <img
        :src="require('@/assets/images/' + about.img)"
        :alt="about.img_info"
        class="about__img"
      />
        <!-- </div> -->
      
      <div class="about__text-container">
        <h2 class="about__heading">{{ about.title }}</h2>
        <p class="about__text">
          {{ about.heading }}
        </p>
        <p class="about__body-text">
          {{ about.body }}
        </p>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      //   about: this.$store.state.about,
    };
  },
  props: {
    about: {
      type: Object,
      required: true,
    },
  },
  name: "ImageExplainerText",
};
</script>

<style lang="scss" scoped>
.about {
  margin: 5rem 0;
  padding: 5rem 0;

  &__container {
    display: flex;
    justify-content: space-around;
    align-items: center;

    @media screen and (max-width: 868px) {
      flex-direction: column-reverse;
      padding-left: 1rem;
      padding-right: 1rem;
    }
  }
  
  &__img-container {
    // width: 50%;
  }


  &__img {

    // min-width: 100%;
    width: 800px;
    height: 450px;
    width: 45%;
    // object-fit: contain;
    height: auto;
    
    @media screen and (max-width: 868px) {
      width: 85vw;
    }
    @media screen and (max-width: 393px) {
      width: 90vw;
    }
    
  }

  &__text-container {
      width: 50%;

    @media screen and (max-width: 868px) {
      width: 71%;
    }
    @media screen and (max-width: 665px) {
      width: 81%;
    }
    @media screen and (max-width: 375px) {
      width: 91%;
    }
  }


  &__heading {
    color: #00865d;
    font-size: 1.75rem;
    font-weight: 600;
    text-transform: uppercase;

    @media screen and (max-width: 550px) {
      // visibility: hidden;
      text-align: center;
  }
  }

  &__text {
    margin-top: 1rem;
    font-size: 4.2rem;
    color: #000;
    line-height: 1.1;

    @media screen and (max-width: 1140px) {
    font-size: calc(2.5rem + 1vw);
    margin-right: 2rem;
  }
    @media screen and (max-width: 550px) {
    text-align: center;
    margin-bottom: 0.5rem;
  }
  }

  &__body-text {
    font-size: 1.8rem;
    max-width: 80%;
    margin-top: 1rem;

    @media screen and (max-width: 868px) {
      max-width: 100%;
      margin-bottom: 3rem;
    }
  }
}
</style>


